"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailGuestSendDynamoMapper = exports.EmailGuestSendJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const dynamodb_mapper_1 = require("../dynamodb-mapper");
const email_guest_send_model_1 = require("./email-guest-send-model");
function modelFromJson(item, mode) {
    const isEmailGuestSend = (0, json_schema_1.createValidator)(email_guest_send_model_1.EmailGuestSend);
    if (isEmailGuestSend(item)) {
        return {
            id: item.id,
            siteId: item.siteId,
            criteria: item.criteria,
            externalService: item.externalService,
            status: item.status,
            statusDetail: item.statusDetail,
            createdAt: new Date(item.createdAt),
            updatedAt: new Date(item.updatedAt),
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isEmailGuestSend.errors ?? [];
    }
}
exports.EmailGuestSendJsonMapper = {
    fromJSON: modelFromJson,
    toJSON: (item) => ({
        id: item.id,
        siteId: item.siteId,
        criteria: item.criteria,
        externalService: item.externalService,
        status: item.status,
        statusDetail: item.statusDetail,
        updatedAt: item.updatedAt.toISOString(),
        createdAt: item.createdAt.toISOString(),
    }),
};
exports.EmailGuestSendDynamoMapper = (0, dynamodb_mapper_1.createDynamoMapper)('EmailGuestSend', exports.EmailGuestSendJsonMapper);
