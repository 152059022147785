"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventBusMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const asset_management_service_1 = require("./asset-management-service");
const domain_management_1 = require("./domain-management");
const email_service_1 = require("./email-service");
const graphql_service_1 = require("./graphql-service");
const live_site_view_service_1 = require("./live-site-view-service");
const show_publish_service_1 = require("./show-publish-service");
const site_export_1 = require("./site-export");
const site_publish_service_1 = require("./site-publish-service");
/**
 * Union of any messages produced to or consumed from the Event Bus by
 * `@backstage/messaging-api`.
 */
exports.EventBusMessage = typebox_1.Type.Union([
    ...asset_management_service_1.AssetManagementMessage.anyOf,
    ...domain_management_1.DomainManagementMessage.anyOf,
    ...live_site_view_service_1.LiveSiteViewServiceMessage.anyOf,
    ...show_publish_service_1.ShowPublishServiceMessage.anyOf,
    ...site_export_1.SiteExportMessage.anyOf,
    ...site_publish_service_1.SitePublishServiceMessage.anyOf,
    email_service_1.EmailMessageEvent,
    graphql_service_1.ShowCreated,
    graphql_service_1.ShowVariableCreated,
    graphql_service_1.SiteVariableCreated,
    graphql_service_1.SiteVariableDeleted,
], { $id: 'EventBusMessage' });
