"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailGuestSend = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestamp_json_1 = require("../timestamp-json");
const model_helpers_1 = require("../model-helpers");
/**
 * Represents a record identifying emails sent to guests of a site.
 */
exports.EmailGuestSend = typebox_1.Type.Object({
    /** Identifier for the record */
    id: typebox_1.Type.String({ format: 'uuid' }),
    /** Identifier for the `Site` with the guest list for emails */
    siteId: typebox_1.Type.String({ format: 'uuid' }),
    /** Filters applied to the `Site` guest list */
    criteria: (0, model_helpers_1.JsonObject)({}),
    /** External service details used for processing the email sends */
    externalService: typebox_1.Type.Optional(typebox_1.Type.Object({
        /** Identifier for the external batch */
        externalBatchId: typebox_1.Type.String(),
        /** Identifier for the service used for sending email */
        externalServiceType: typebox_1.Type.Union([typebox_1.Type.Literal('sendgrid')]),
    })),
    /** Status of the email send */
    status: typebox_1.Type.Union([
        typebox_1.Type.Literal('draft'),
        typebox_1.Type.Literal('pending'),
        typebox_1.Type.Literal('canceled'),
        typebox_1.Type.Literal('error'),
        typebox_1.Type.Literal('complete'),
    ]),
    /**
     * Additional details pertaining to the status, generally relevant for error
     * state details
     */
    statusDetail: (0, model_helpers_1.JsonObject)(),
    ...timestamp_json_1.TimestampsJson.properties,
}, { $id: 'EmailGuestSend' });
